.hover .td
{
    white-space: nowrap; 
    max-width: 225px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: inline-block; */
}

.hover:hover .td{
    color: red!important;
    max-width: 225px!important;
    white-space: unset; 
    overflow: visible;
    text-overflow: ellipsis;
    
}
